import React, {Suspense} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import LanguageProvider from '../languageProvider/LanguageProvider';
import {utm} from '../utm/utm';
import Header from '../header/Header';
import Footer from '../header/Footer';
import CookiesPOPUP from '../cookies/CookiesPOPUP';
import NotFound from '../404/NotFound';

import * as tracker from '../tracker';
import {getCookie} from '../cookies';

import HomepageQuantiacs from '../homepageQuantiacs/HomepageQuantiacs';
import CookiesQuantiacs from '../cookies/CookiesQuantiacs';

const TermsOfUse = React.lazy(() => import('../termsofuse/TermsOfUse'));
const PersonalData = React.lazy(() => import('../personaldata/PersonalData'));
const Vacancy = React.lazy(() => import('../career/vacancy/Vacancy'));
const OpenPositions = React.lazy(() => import('../career/openpositions/OpenPositions'));
const FAQ = React.lazy(() => import('../faq/FAQ'));
const Contacts = React.lazy(() => import('../contacts/Contacts'));

const ContestQuantiacs = React.lazy(() => import('../competition/ContestQuantiacs'));

const Leaderboard = React.lazy(() => import('../leaderboardQuantiacs/Leaderboard'));
const LeaderboardQuantiacsQ15 = React.lazy(() => import('../leaderboardQuantiacs/LeaderboardQ15'));
const LeaderboardQuantiacsQ16 = React.lazy(() => import('../leaderboardQuantiacs/LeaderboardQ16'));
const LeaderboardQuantiacsQ17 = React.lazy(() => import('../leaderboardQuantiacs/LeaderboardQ17'));
const LeaderboardQuantiacsQ18 = React.lazy(() => import('../leaderboardQuantiacs/LeaderboardQ18'));
const LeaderboardQuantiacsQ19 = React.lazy(() => import('../leaderboardQuantiacs/LeaderboardQ19'));
const LeaderboardQuantiacsQ20 = React.lazy(() => import('../leaderboardQuantiacs/LeaderboardQ20'));
const LeaderboardQuantiacsQ21 = React.lazy(() => import('../leaderboardQuantiacs/LeaderboardQ21'));

const FundingLeaderboard = React.lazy(() => import('../leaderboardFunding/FundingLeaderboard'));
const StatisticSubmissionPage = React.lazy(() => import('../statisticSubmission'));

const ContestQuantiacsFull15 = React.lazy(() => import('../competition/ContestQuantiacsFull15'));
const ContestQuantiacsFull16 = React.lazy(() => import('../competition/ContestQuantiacsFull16'));
const ContestQuantiacsFull17 = React.lazy(() => import('../competition/ContestQuantiacsFull17'));
const ContestQuantiacsFull18 = React.lazy(() => import('../competition/ContestQuantiacsFull18'));
const ContestQuantiacsFull19 = React.lazy(() => import('../competition/ContestQuantiacsFull19'));
const ContestQuantiacsFull20 = React.lazy(() => import('../competition/ContestQuantiacsFull20'));
const ContestQuantiacsFull21 = React.lazy(() => import('../competition/ContestQuantiacsFull21'));

const ReferralProgram = React.lazy(() =>
    import('../referralProgram/ReferralProgramForAdvertising'),
);

class App extends React.Component {
    componentDidMount() {
        const utmName = 'utm_is_exist';
        if (!isUtmExist(utmName)) {
            setUtmInCookieWithoutReferral(utmName);
        }

        setReferral();

        const qn_uid = getCookie('qn_uid');
        tracker.userInfo({qntId: qn_uid});

        function isUtmExist(utmName) {
            const currentUtm = getCookie(utmName);
            return (
                currentUtm !== '' &&
                currentUtm !== undefined &&
                currentUtm !== null &&
                currentUtm !== 'undefined' &&
                Object.keys(currentUtm).length !== 0
            );
        }

        function setUtmInCookieWithoutReferral(utmName) {
            const metrics = utm(window.location.href, '&');
            for (const [key, value] of Object.entries(metrics)) {
                const item = `${key}=${value}; path=/; max-age=800000`;
                if (item !== 'utm_source=reference; path=/; max-age=800000') {
                    document.cookie = item;
                }
            }
            document.cookie = `${utmName}=exist`;
        }

        function setReferral() {
            const metrics = utm(window.location.href, '&');
            if (metrics.utm_source && metrics.utm_source === 'reference') {
                const utmSource = getCookie('utm_source');
                if (utmSource !== 'reference') {
                    tracker.event('REFERRAL_PROGRAM_OPEN_REFERRAL_LINK_FIRST');
                }

                const medium = `utm_medium=${metrics.utm_medium}; path=/; max-age=800000`;
                document.cookie = medium;
                const source = `utm_source=${metrics.utm_source}; path=/; max-age=800000`;
                document.cookie = source;
                tracker.event('REFERRAL_PROGRAM_OPEN_REFERRAL_LINK_TOTAL');
            }
        }
    }

    render() {
        return (
            <BrowserRouter>
                <div className="wrapper">
                    <LanguageProvider>
                        <div>
                            <Header />
                        </div>
                        <main className="mainWrapper">
                            <Suspense fallback={<div>Loading</div>}>
                                <Routes>
                                    <Route exact path="/" element={<HomepageQuantiacs />} />
                                    <Route
                                        exact
                                        path="/leaderboard"
                                        element={<FundingLeaderboard />}
                                    />
                                    <Route
                                        exact
                                        path="/leaderboard/15"
                                        element={<LeaderboardQuantiacsQ15 />}
                                    />
                                    <Route
                                        exact
                                        path="/leaderboard/16"
                                        element={<LeaderboardQuantiacsQ16 />}
                                    />
                                    <Route
                                        exact
                                        path="/leaderboard/17"
                                        element={<LeaderboardQuantiacsQ17 />}
                                    />
                                    <Route
                                        exact
                                        path="/leaderboard/18"
                                        element={<LeaderboardQuantiacsQ18 />}
                                    />
                                    <Route
                                        exact
                                        path="/leaderboard/19"
                                        element={<LeaderboardQuantiacsQ19 />}
                                    />
                                    <Route
                                        exact
                                        path="/leaderboard/20"
                                        element={<LeaderboardQuantiacsQ20 />}
                                    />
                                    <Route
                                        exact
                                        path="/leaderboard/21"
                                        element={<LeaderboardQuantiacsQ21 />}
                                    />
                                    <Route exact path="/career" element={<OpenPositions />} />
                                    <Route
                                        exact
                                        path="/career/openpositions"
                                        element={<OpenPositions />}
                                    />
                                    <Route
                                        exact
                                        path="/career/openpositions/:id"
                                        element={<Vacancy />}
                                    />

                                    <Route exact path="/termsofuse" element={<TermsOfUse />} />
                                    <Route exact path="/personalinfo" element={<PersonalData />} />
                                    <Route exact path="/cookies" element={<CookiesQuantiacs />} />
                                    <Route exact path="/contest" element={<ContestQuantiacs />} />
                                    <Route
                                        exact
                                        path="/statistic/:submissionId"
                                        element={<StatisticSubmissionPage />}
                                    />
                                    <Route
                                        exact
                                        path="/contest/15"
                                        element={<ContestQuantiacsFull15 />}
                                    />
                                    <Route
                                        exact
                                        path="/contest/16"
                                        element={<ContestQuantiacsFull16 />}
                                    />
                                    <Route
                                        exact
                                        path="/contest/17"
                                        element={<ContestQuantiacsFull17 />}
                                    />
                                    <Route
                                        exact
                                        path="/contest/18"
                                        element={<ContestQuantiacsFull18 />}
                                    />
                                    <Route
                                        exact
                                        path="/contest/19"
                                        element={<ContestQuantiacsFull19 />}
                                    />
                                    <Route
                                        exact
                                        path="/contest/20"
                                        element={<ContestQuantiacsFull20 />}
                                    />
                                    <Route
                                        exact
                                        path="/contest/21"
                                        element={<ContestQuantiacsFull21 />}
                                    />
                                    <Route exact path="/faq" element={<FAQ />} />
                                    <Route exact path="/contacts" element={<Contacts />} />
                                    <Route exact path="/referral" element={<ReferralProgram />} />
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </Suspense>
                            <CookiesPOPUP />
                        </main>
                        <Footer />
                    </LanguageProvider>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
