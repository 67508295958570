import React from 'react';
import '../../css/style.scss';
import styled from 'styled-components';

const Images = {
    stock: require('./nasdaq_gz.webp'),
    futures: require('./futures_gz.webp'),
    cryptodaily: require('./cryptodaily_gz.webp'),
    background: require('../../img/homepage/background_blue_graph.webp'),
};

const AboutDatasetDiv = styled.div`
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    color: rgb(168, 160, 149);

    .middle_theme {
        background-image: url(${Images.background}) !important;
    }

    img {
        display: none;
        width: 100%;
        max-width: 480px;
    }
    .dataset_header {
        font-size: 20px;
        line-height: 1.5;
        color: rgb(232, 230, 227);
    }
    .dataset {
        display: flex;
        margin: auto;
        max-width: 1380px;
        justify-content: space-evenly;
        align-items: stretch;
        padding-bottom: 20px;
    }
    .dataset_description {
    }

    @media (min-width: 768px) {
        img {
            display: inline;
        }
        .dataset_description {
            width: 50%;
            display: flex;
            margin: auto;
            align-items: center;
            flex-direction: column;
        }
    }
`;

function AboutDataset() {
    return (
        <AboutDatasetDiv className="quantiacs_in_the_press">
            <section className="middle_theme">
                <h2 className="section_header">Market Data</h2>
                <div className="datasets_all">
                    <div className="dataset">
                        <div className="dataset_description">
                            <div className="dataset_header">Stocks</div>
                            <p>
                                Quantiacs provides a survivorship-free market dataset for companies
                                which are or have been listed on the NASDAQ exchange.
                            </p>
                        </div>
                        <div>
                            <img src={Images.stock} alt="Stocks" />
                        </div>
                    </div>
                    <div className="dataset">
                        <div className="dataset_description">
                            <div className="dataset_header">Futures</div>
                            <p>
                                Quantiacs provides data for liquid global futures contracts. The
                                underlying assets are commodities (energy, metals, agricultural
                                goods), currency rates and financial assets: stock indices and
                                bonds.
                            </p>
                        </div>
                        <div>
                            <img src={Images.futures} alt="Futures" />
                        </div>
                    </div>
                    <div className="dataset">
                        <div className="dataset_description">
                            <div className="dataset_header">Cryptocurrency Daily Data</div>
                            <p>
                                Quantiacs provides up-to-date survivorship-free data for
                                cryptocurrencies with the largest market capitalization.
                            </p>
                        </div>
                        <div>
                            <img src={Images.cryptodaily} alt="Cryptocurrency Daily Data" />
                        </div>
                    </div>
                </div>
            </section>
        </AboutDatasetDiv>
    );
}

export default AboutDataset;
