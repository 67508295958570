import React from 'react';
import '../../css/style.scss';
import styled from 'styled-components';

const Images = {
    bts: require('./contest_btc_g_gimp.webp'),
    futures: require('./contest_futures_g_gimp.webp'),
    stock: require('./contest_stock_g_gimp.webp'),
    cryptodaily: require('./contest_crypto_g_gimp.webp'),
};

const ContestDiv = styled.div`
    color: #a9a095;
    background: #060b13;
    padding: 50px;

    .section_header {
        color: #a9a095;
    }

    .competition_img {
        background-color: white;
        display: block;
        margin: 0 auto;
        max-width: 180px;
    }

    .about_crypto_contests,
    a {
        font-size: 18px;
    }

    @media (min-width: 768px) {
        .competition_img {
            max-width: 180px;
        }
    }
    @media (min-width: 1150px) {
        .competition_img {
            max-width: 280px;
        }
    }

    @media (max-width: 480px) {
        padding: 20px;
    }

    li,
    .dark_theme_strong {
        color: white;
    }

    .section_ul {
        color: white;
    }

    .contest_logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
    }
`;

class AboutCompetition extends React.Component {
    render() {
        return (
            <ContestDiv className="quantiacs_in_the_press">
                <h2 className="section_header">About the Quantiacs Contests</h2>
                <div className="quantiacs_in_the_press_container">
                    <div className="offer_item">
                        <div className="contest_logo">
                            <img
                                className="competition_img"
                                src={Images.stock}
                                alt="The NASDAQ-100 Stock Long-Short contest"
                            />
                        </div>

                        <a href="/contest">The NASDAQ-100 Stock Long-Short contest</a>
                    </div>

                    <div className="offer_item">
                        <div className="contest_logo">
                            <img
                                className="competition_img"
                                src={Images.cryptodaily}
                                alt="The crypto Top-10 Long-only contest"
                            />
                        </div>
                        <div className="about_crypto_contests">
                            {' '}
                            The crypto contests: <a href="/leaderboard/16">Long-Only</a> and{' '}
                            <a href="/leaderboard/17">Long-Short</a>
                        </div>
                    </div>

                    <div className="offer_item">
                        <div className="contest_logo">
                            <img
                                className="competition_img"
                                src={Images.futures}
                                alt=" The classic Quantiacs Futures contest"
                            />
                        </div>
                        <a href="/leaderboard/15">The classic Quantiacs Futures contest</a>
                    </div>
                    <div className="offer_item">
                        <div className="contest_logo">
                            <img
                                className="competition_img"
                                src={Images.bts}
                                alt="The crypto Bitcoin Futures contest"
                            />
                        </div>

                        <a href="/leaderboard/15">The crypto Bitcoin Futures contest</a>
                    </div>
                </div>
            </ContestDiv>
        );
    }
}

export default AboutCompetition;
