import React from 'react';
import '../../css/style.scss';
import styled from 'styled-components';

const Images = {
    bls: require('./bls_gz.webp'),
    imf: require('./imf_gz.webp'),
    background: require('../../img/homepage/background_green_dark_g.webp'),
};

const AboutDatasetDiv = styled.div`
    text-align: center;
    font-size: 18px;
    line-height: 30px;

    background-image: url(${Images.background});
    background-repeat: repeat;

    img {
        display: none;
        width: 100%;
        max-width: 480px;
    }
    .dataset_header {
        font-size: 20px;
        line-height: 1.5;
        color: rgb(232, 230, 227);
    }
    .dataset {
        display: flex;
        margin: auto;
        max-width: 1380px;
        justify-content: space-evenly;
        align-items: stretch;
        padding-bottom: 20px;
    }
    .dataset_description {
    }

    @media (min-width: 768px) {
        img {
            display: inline;
        }
        .dataset_description {
            width: 50%;
            display: flex;
            margin: auto;
            align-items: center;
            flex-direction: column;
        }
    }
`;

function AboutDatasetAdditional() {
    return (
        <AboutDatasetDiv className="quantiacs_in_the_press">
            <section>
                <h2 className="section_header">Additional datasets</h2>
                <div className="datasets_all">
                    <div className="dataset">
                        <div>
                            <img src={Images.bls} alt="BLS Data" />
                        </div>
                        <div className="dataset_description">
                            <div className="dataset_header">Using BLS Data</div>
                            <p>
                                The U.S. Bureau of Labor Statistics is the principal agency for the
                                U.S. government in the field of labor economics and statistics. It
                                provides macroeconomic data in several interesting categories:
                                prices, employment and unemployment, compensation and working
                                conditions and productivity.
                            </p>
                        </div>
                    </div>
                    <div className="dataset">
                        <div>
                            <img src={Images.imf} alt="IMF Data" />
                        </div>
                        <div className="dataset_description">
                            <div className="dataset_header">Using IMF Data</div>
                            <p>
                                The International Monetary Fund (IMF) publishes a range of time
                                series data on IMF lending, exchange rates and other economic and
                                financial indicators and information about commodity data.
                            </p>
                        </div>
                    </div>
                    {/* <div className="dataset"> */}
                    {/*    <div> */}
                    {/*        <img src={Images.imf_currency} /> */}
                    {/*    </div> */}
                    {/*    <div className="dataset_description"> */}
                    {/*        <div className="dataset_header">Using IMF Currency Data</div> */}
                    {/*        <p> */}
                    {/*            The International Monetary Fund (IMF) publishes a range of time */}
                    {/*            series data on IMF lending, exchange rates and other economic and */}
                    {/*            financial indicators. */}
                    {/*        </p> */}
                    {/*    </div> */}
                    {/* </div> */}
                </div>
            </section>
        </AboutDatasetDiv>
    );
}

export default AboutDatasetAdditional;
