import React from 'react';
import '../../css/style.scss';

function Announce() {
    return (
        <div className="homepage_announce">
            <a
                rel="noopener noreferrer"
                href={`${window.location.origin.toString()}/personalpage/login`}
                target="_self"
            >
                NEW: JOIN THE Q21 CONTEST AND GET ACCESS TO FUNDAMENTAL DATA FOR NASDAQ STOCKS!
            </a>
        </div>
    );
}

export default Announce;
