import React from 'react';
import '../../css/style.scss';
import {LanguageConsumer} from '../../Localisation';

import {ReactComponent as YourSvg} from '../../img/homepage/dark2.svg';

class AboutEcosystem extends React.Component {
    render() {
        return <LanguageConsumer>{({language}) => this.renderEnQuantiacs()}</LanguageConsumer>;
    }

    renderEnQuantiacs() {
        return (
            <div className="ecosystem_container">
                <h2 className="section_header">Ecosystem</h2>
                <div className="ecosystem_main">
                    <div className="ecosystem_left">
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Python</div>
                            <p>Code strategies in Python</p>
                        </div>
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Install Libraries</div>
                            <p>
                                Each strategy runs in an isolated customizable environment, where
                                you can install libraries such as TensorFlow
                            </p>
                        </div>
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Cloud Servers</div>
                            <p>
                                Run your strategies on the Quantiacs cloud: 8G RAM and 1 Xeon
                                E5-1650 core for each system. Or{' '}
                                <a href="/documentation/en/user_guide/local_development.html">
                                    develop offline
                                </a>{' '}
                                and then submit
                            </p>
                        </div>
                    </div>
                    <div className="ecosystem_center">
                        <div>
                            <YourSvg></YourSvg>
                        </div>
                    </div>
                    <div className="ecosystem_right">
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Jupyter</div>
                            <p>
                                Use Jupyter Notebook and JupyterLab for coding and plotting results
                            </p>
                        </div>
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Crypto, Futures</div>
                            <p>
                                Quantiacs hosts contests for{' '}
                                <a href="/documentation/en/user_guide/data.html">
                                    futures and cryptocurrencies
                                </a>
                                .
                            </p>
                        </div>
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Free Research</div>
                            <p>
                                Quantiacs provides{' '}
                                <a href="https://github.com/quantiacs">open-source libraries</a> for{' '}
                                <a href="/documentation/en/user_guide/local_development.html">
                                    testing algorithms locally
                                </a>{' '}
                                on your own machine.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutEcosystem;
