import React from 'react';
import '../../css/style.scss';
import styled from 'styled-components';

import imgUsdEur from '../../img/homepage/main_slogan.webp';

const SloganContainerDiv = styled.div`
    background: #141526 url(${imgUsdEur}) round;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        border-radius: 0;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        height: auto !important;
        align-items: flex-end;
        padding: 0 2px;
    }
`;

const SloganDiv = styled.div`
    margin: 15px auto auto;
    max-width: 1380px;
    padding: 0 30px 10px;

    div {
        text-align: center;
    }

    h1 {
        font-size: 2rem;
        color: white;
        text-align: center;
        margin-top: 3rem;
        text-transform: uppercase;
        font-family: 'Gotham Pro Regular';
    }

    p {
        padding: 30px 0px;
        color: white;
    }

    .slogan_p {
        text-shadow: 1px 1px 1px black;
    }

    .slogan_text {
        line-height: 1.5;
        color: #000;

        .quantiacs_start_link {
            text-align: center;
            color: #fff;
            border: none;
            cursor: pointer;
            padding: 20px 50px;
            text-decoration: none;
            font-size: 1.3em;
            transition: all 0.3s ease-in;
            min-width: 200px;
            width: 200px;
            height: 50px;
            background: #1bbafb;
            border-radius: 10px;
            box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
                0 1px 5px 0 rgb(0 0 0 / 12%);
        }
    }

    .slogan_video {
        max-height: 400px;
    }

    @media (min-width: 1050px) {
        display: flex;
        justify-content: space-evenly;
        align-items: stretch;

        padding-bottom: 25px;

        .slogan_video {
            display: inline;
            min-width: 600px;
        }

        .slogan_text {
            max-width: 606px;
        }
    }
`;

function Slogan() {
    return (
        <SloganContainerDiv>
            <SloganDiv>
                <div className="slogan_text">
                    <h1>The World's Leading Quant Platform</h1>
                    <p className="slogan_p">
                        Join our quant community and compete for 2M USD in allocations to equity
                        long-short systems.
                    </p>
                    <p>
                        <a
                            className="quantiacs_start_link"
                            rel="noopener noreferrer"
                            href={`${window.location.origin.toString()}/personalpage/login`}
                            target="_self"
                        >
                            REGISTER
                        </a>
                    </p>
                </div>
                <div className="slogan_video">
                    <iframe
                        width="100%"
                        height="380"
                        src="https://www.youtube.com/embed/6XwGPT-5uxU"
                        title="YouTube video Quick start Quantiacs"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </SloganDiv>
        </SloganContainerDiv>
    );
}

export default Slogan;
